import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-product-development-container" }, [_c("edit-modal-composer", { attrs: { "custom-list-url": _vm.prodApiUrl, "custom-url": _vm.customUrl, "title": _vm.title }, scopedSlots: _vm._u([{ key: "actions", fn: function(ref) {
    var cancel = ref.cancel;
    var isLoading = ref.isLoading;
    var submit = ref.submit;
    return [_c("a-button", { on: { "click": cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": isLoading, "type": "primary" }, on: { "click": submit } }, [_vm._v("Save")])];
  } }]) }, [_c("text-input", { key: "projectNo", attrs: { "label": "Project Number", "span": 24, "form-item": "", "rules": "required", "max-length": 50 } }), _c("date-picker", { key: "sbtCompleted", attrs: { "label": "SBT Completed", "rules": "required", "span": 12 } }), _c("date-picker", { key: "mbtCompleted", attrs: { "label": "MBT Completed", "rules": "required", "span": 12 } }), _c("text-input", { key: "canInkjetCode", attrs: { "label": "Can Inkjet Code", "span": 12, "disabled": true } }), _c("date-picker", { key: "initialProduction", attrs: { "label": "Initial Production", "span": 12 } }), _c("checkbox-input", { key: "tpCompleted", attrs: { "span": 12, "form-item": false, "label": "TP Completed" } }), _c("checkbox-input", { key: "tpSent", attrs: { "span": 12, "label": "TP Sent" } }), _c("checkbox-input", { key: "newProcessNow", attrs: { "span": 12, "label": "Process Setup" } }), _c("checkbox-input", { key: "finalRecipe", attrs: { "span": 12, "label": "Final Recipe" } })], 1)], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditProductDevelopment",
  inject: ["crud", "prodApiUrl", "prodDevId"],
  data() {
    return {
      customUrl: this.prodApiUrl + `/${this.prodDevId}`
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    },
    title() {
      return !this.entity ? "Edit" : `Edit Product Number ${this.entity.projectNo}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditProductDevelopment = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-product-development" }, [_c("resource", { attrs: { "name": "sterilization.audit-groups.items.product-developments", "api-url": _vm.apiUrl, "redirect-route": _vm.redirectRoute, "edit-route-id-name": "prodDevId" } }, [_c("edit-product-development")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditProductDevelopment
  },
  data() {
    return {
      apiUrl,
      groupId: this.$route.params.id,
      itemId: this.$route.params.itemId,
      prodDevId: this.$route.params.prodDevId
    };
  },
  provide() {
    return {
      prodDevId: this.prodDevId,
      prodApiUrl: `${this.apiUrl}/sterilization/audit-groups/${this.groupId}/items/${this.itemId}/product-developments`
    };
  },
  computed: {
    redirectRoute() {
      return `/sterilization-audits/audit-groups/${this.groupId}/item/${this.itemId}`;
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
